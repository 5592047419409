<template>
    <basicLayout>
        <h5>LOCKED ORDERS</h5>
        <div class="row">
            <div class="col-xl-6">
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Search..</span>
                    </div>
                    <input
                           @input="debounce_search"
                           :value="search"
                           type="text"
                           class="form-control"
                           placeholder="PO Number, Name, Code or Title" />
                    <div class="input-group-append">
                        <button
                                @click="clear_search"
                                class="btn btn-outline-secondary"
                                type="button">
                            Clear
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="btn-toolbar d-none d-lg-block">
                    <button
                            @click="download_csv"
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                            :class="{ active: outstanding_only }">
                        Download CSV
                    </button>
                </div>
            </div>
        </div>

        <hr />
        <div class="row mb-2 mt-2">
            <div class="col text-right">
                <b>Showing: {{ filtered.length }}</b> of
                <b>{{ display_lines.length }}</b>
            </div>
        </div>

        <div
             v-if="!isLoading"
             class="tableFixHead">
            <table class="table table-bordered table-hover table-sm">
                <thead class="thead-dark">
                    <tr>
                        <th
                            @click="sort_by('created')"
                            class="text-left">
                            Date
                        </th>
                        <th
                            @click="sort_by('created')"
                            class="text-left">
                            Age
                        </th>
                        <th
                            @click="sort_by('po_number')"
                            class="text-left">
                            PO No.
                        </th>
                        <th
                            @click="sort_by('name_code')"
                            class="text-left">
                            To
                        </th>
                        <th
                            @click="sort_by('stock_code')"
                            class="text-left">
                            Code
                        </th>
                        <th
                            @click="sort_by('description')"
                            class="text-left">
                            Title
                        </th>
                        <!-- <th @click="sort_by('description')" class="text-right">
                Release
              </th> -->
                        <th
                            @click="sort_by('price')"
                            class="text-left">
                            Price
                        </th>

                        <th class="text-right">Outstanding</th>
                        <th
                            @click="sort_by('quantity')"
                            class="text-right">
                            Ordered
                        </th>
                        <th
                            @click="sort_by('allocated')"
                            class="text-right">
                            Allocated
                        </th>
                        <th
                            @click="sort_by('locked')"
                            class="text-right">
                            Locked
                        </th>
                        <th
                            @click="sort_by('picking')"
                            class="text-right">
                            Handling
                        </th>

                        <th
                            @click="sort_by('soh')"
                            class="text-right">
                            SOH
                        </th>



                        <!-- <th @click="sort_by('picked')" class="text-right">Picked</th> -->
                        <th
                            @click="sort_by('shipped')"
                            class="text-right">
                            Shipped
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="line in filtered">
                        <tr
                            :key="line.id"
                            :class="prod_class(line)">
                            <td
                                class="text-left"
                                :title="line.last_update | moment_long">
                                {{ line.created | moment_short }}
                            </td>
                            <td
                                @dblclick="filter_by(line.created)"
                                class="text-right d-none d-sm-table-cell">
                                {{ days_ago(line.created) }} days
                            </td>
                            <td
                                @dblclick="filter_by(line.po_number)"
                                class="text-left d-none d-sm-table-cell">
                                {{ line.po_number }}
                            </td>
                            <td
                                @dblclick="filter_by(line.name_code)"
                                class="text-left">
                                {{ line.name }} ({{ line.name_code }})
                            </td>
                            <td
                                @dblclick="filter_by(line.stock_code)"
                                class="text-left">
                                {{ line.stock_code }}
                            </td>
                            <td class="text-left">{{ line.description }}</td>
                            <!-- <td class="text-left">{{ line.description }}</td> -->
                            <td class="text-right">{{ line.price | currency }}</td>
                            <!-- <td class="text-left">{{ line.is_outstanding }}</td> -->
                            <td class="text-right">
                                {{ line.quantity - line.allocated - line.picking - line.picked - line.shipped }}
                            </td>
                            <td class="text-right">{{ line.quantity }}</td>
                            <td class="text-right">{{ line.allocated }}</td>

                            <td class="text-center">
                                <div class="form-check">
                                    <input
                                           v-model="line.locked"
                                           type="checkbox"
                                           class="form-check-input"
                                           :title="line.locked_by"
                                           @change="lock_allocation(line)" />
                                </div>
                            </td>
                            <td class="text-right">{{ line.picking + line.picked }}</td>
                            <!-- <td class="text-right">{{ line.picked }}</td> -->
                            <td class="text-right">{{ line.line_product.stockonhand }}</td>
                            <td class="text-right">{{ line.shipped }}</td>
                            <!-- <td class="text-center w-5">
                                <button
                                        @click="delete_item(line)"
                                        type="button"
                                        class="btn btn-x-sm btn-outline-danger">
                                    <trash-icon
                                                size="1x"
                                                class="custom-class"></trash-icon>
                                    &nbsp;{{ confirm_delete_ids.includes(line.id) ? "Really?" : "Delete" }}
                                </button>
                            </td> -->
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <Pagination v-if="pages" :pages="pages" :selected_page="selected_page" v-on:updatePage="updatePage" />
    </basicLayout>
</template>

<script>
import moment from "moment";
import { db } from "@/firebaseConfig";
import { actions, getters } from "@/store";
import { orderBy } from "lodash";
import basicLayout from "@/components/basicLayout";
import Pagination from "@/components/Pagination";
import orders_mixins from "@/mixins/orders_mixins";
import filter_mixins from "@/mixins/filter_mixins";
import Papa from "papaparse";
import { TrashIcon } from "vue-feather-icons";

const lines = db.collection("lines");

export default {
    name: "orders",

    data() {
        return {
            display_lines: [],
            products: [],
            lines_filters: ["outstanding_lines", "locked_lines"],
            outstanding_only: true,
            deleted_titles: true,
            age: 56,
            old_lines: false,
            search: "",
            sort_column: ["code"],
            sort_order: "asc",
            page_limit: 300,
            selected_page: 1,
            locked_products: false,
            confirm_delete_ids: [],
            confirm_delete: false,
            delete_timeouts: [],
        };
    },
    components: { basicLayout, Pagination, TrashIcon },
    mixins: [orders_mixins, filter_mixins],

    created() {
        actions.setLoading(true);
        this.get_products();
    },

    methods: {
        get_products: async function () {
            const productsSnapshot = await db
                .collection("products")
                .where("is_outstanding", "==", true)
                .get();

            const products = productsSnapshot.docs.map((doc) => {
                const product_data = doc.data();
                product_data.id = doc.id;
                return product_data;
            });

            console.log(products[0]);
            console.log("OUSTANDING PRODUCTS: " + products.length);

            this.products = products;
        },
        debounce_search(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.search = event.target.value;
            }, 600);
        },
        updatePage(value) {
            this.selected_page = value;
            this.clear_search();
        },
        filter_by(filter) {
            this.search = filter;
        },
        sort_by(column) {
            this.sort_column = column;
            this.sort_order = this.sort_order === "asc" ? "desc" : "asc";
        },
        clear_search() {
            this.search = "";
        },
        days_ago(date) {
            const m = moment(date.toDate());
            const today = moment().startOf("day");
            return Math.round(moment.duration(today - m).asDays());
        },
        lock_allocation(line) {
            const locked_by = (line.locked && this.currentUser.email) || null;
            db.collection("lines").doc(line.id).update({ locked: line.locked, locked_by });
        },
        download_csv(event) {
            // console.log(event);
            if (this.filtered.length === 0) return;

            const download_data = this.filtered.map((line) => {
                // line.created = line.created.toDate();
                line.ordered = line.quantity;
                line.outstanding = line.quantity - line.allocated - line.picking - line.picked - line.shipped;
                line.stock_code_text = `\`${line.stock_code}`;
                line.barcode_text = `\`${line.barcode}`;
                return line;
            });

            const csv = Papa.unparse({
                fields: [
                    "name_code",
                    "name",
                    "po_number",
                    "stock_code",
                    "stock_code_text",
                    "description",
                    "barcode",
                    "barcode_text",
                    "price",
                    "category1",
                    "ordered",
                    "outstanding",
                    "allocated",
                    "handling",
                    "shipped",
                ],
                data: download_data,
                quotes: true,
            });
            const csv_name = moment().format("YYYY-MM-DD") + "_order_lines.csv";

            console.log(csv_name);
            this.downloading = false;
            const anchor = document.createElement("a");
            anchor.href = "data:text/csv," + encodeURIComponent(csv);
            anchor.download = csv_name;
            anchor.click();
        },



        prod_class(line) {
            if (line.deleted_title) return "text-red";
        },
    },

    computed: {
        isLoading() {
            return getters.isLoading();
        },
        filtered() {
            let filtered_lines = this.display_lines.slice();
            let visible_lines = [];
            // const search = this.search;

            if (this.sort_column) {
                filtered_lines = orderBy(filtered_lines, (item) => item[this.sort_column] || "", this.sort_order);
            }

            if (this.lines_filters.includes("deleted_titles")) {
                filtered_lines = filtered_lines.filter((line) => {
                    return line.deleted_title;
                });
            }

            if (this.lines_filters.includes("old_lines")) {
                filtered_lines = filtered_lines.filter((line) => {
                    return line.created && this.days_ago(line.created) > this.age;
                });
            }

            // console.log(visible_lines.length);

            filtered_lines = filtered_lines.filter((line) => {
                const s = Object.values(line);
                const su = s.toString().toUpperCase();
                return su.match(this.search.toString().toUpperCase());
            });

            filtered_lines = filtered_lines.map((line) => {
                const product = this.products.find((p) => p.code == line.stock_code);
                line.line_product = product;
                return line;
            });

            return filtered_lines;
        },

        pages() {
            let lines_array = this.lines;

            if (this.search || this.outstanding_only) {
                lines_array = this.filtered;
            }

            return Math.ceil(lines_array.length / this.page_limit);
        },
    },

    watch: {
        lines_filters: {
            immediate: true,
            handler(filter) {
                actions.setLoading(true);
                let query = lines.where("locked", "==", true).where("is_outstanding", "==", true);
                this.$bind("display_lines", query);
            },
        },

        search: function (newSearch) {
            if (newSearch && newSearch.length > 0) {
                this.selected_page = 1;
            }
        },

        display_lines: function (new_lines) {
            if (new_lines.length > 1) {
                actions.setLoading(false);
            }
        },
    },
};
</script>

<style scoped></style>
